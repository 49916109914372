import React from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";
import { useMutation } from "react-query";

import { LayoutWrapper, Input } from "../components";
import { forgotPwd } from "../apis";

const Forgot = () => {
  const { register, handleSubmit, formState, setError } = useForm();

  const mutation = useMutation((email) => forgotPwd(email));
  console.log("formState", formState.errors);
  const onSubmit = async (data) => {
    if (mutation.isLoading) return;
    console.log("data", data);
    try {
      const res = await mutation.mutateAsync(data);
      navigate(`/forgot-success?email=${data.email}`);
    } catch (error) {
      if (error.response.status === 400) {
        setError("email", {
          type: "apiValidate",
          message: "Incorrect email. Please try again.",
        });
      }
    }
  };
  return (
    <LayoutWrapper
      title={"Forgot password"}
      subTitle={
        "Enter your registered email address and we’ll send you a password reset email."
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email Address"
          placeholder="Email Address"
          {...register("email", {
            required: {
              value: true,
              message: "this field is required",
            },
          })}
          error={formState.errors?.email}
        ></Input>
        <button type="submit" className="register__btn">
          {mutation.isLoading ? "Sending..." : "Send Reset Email"}
        </button>
      </form>
      <span className="register__link" onClick={() => navigate("/login")}>
        Back to log in
      </span>
    </LayoutWrapper>
  );
};

export default Forgot;
